export const logClick = (id, category) => {
  window.analytics.track('click', {
    'element_category': category,
    'element_name': id.trim().split(' ').join('-').toLowerCase(),
  });
}

export const logButtonClick = (id) => {
  logClick(id, 'button');
}

export const logLinkClick = (id) => {
  logClick(id, 'link');
}
