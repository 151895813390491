import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';

import Menu from './Menu';

import { logLinkClick } from '../utils';

const styles = (theme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '5rem',
    maxWidth: 1024,
    margin: '0 auto',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    marginLeft: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    fontSize: 20,
    fontWeight: 800,
    color: theme.palette.text.primary,
  },
  logoSubscript: {
    fontWeight: 400,
    fontSize: 14,
  },
  logoLink: {
    fontWeight: 400,
    fontSize: 16,
  },
  space: {
    flex: 1,
  },
  links: {
    textAlign: 'right',
    marginRight: '0.75rem',
    '& a': {
      textDecoration: 'none',
      marginLeft: '2rem',
      color: theme.palette.primary.main,
    },
    '& a:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    }
  },
});

const renderLinks = links => {
  return links.map(item => (
    <Link
      key={item.title}
      href={item.url}
      target={item.external ? "_blank" : "_self"}
      variant="subtitle1"
      color="textSecondary"
      onClick={() => { logLinkClick(`nav:${item.title}`) }}
    >
      {item.title}
    </Link>
  ));
};

class Bar extends Component {

  render() {
    // Styling
    const { classes } = this.props;

    const { links } = this.props;

    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <div>PGN to PDF&nbsp;</div>
              <div style={{ display: 'flex' }}>
                <span className={classes.logoSubscript}>by&nbsp;</span>
                <Link
                  href={"https://chessvision.ai"}
                  className={classes.logoLink}
                  color="textPrimary"
                  target="_blank"
                  onClick={() => { logLinkClick('nav:logo') }}
                >Chessvision.ai</Link>
              </div>
            </div>
          </div>
          <div className={classes.space}></div>
          <Hidden smDown>
            <div className={classes.links}>
              { renderLinks(links) }
            </div>
          </Hidden>
          <Hidden xsDown mdUp>
            <div className={classes.links}>
              { renderLinks(links.slice(0, 4)) }
            </div>
          </Hidden>
          <Hidden smUp>
            <Menu title="Our Apps" links={links} />
          </Hidden>
        </div>
      </div>
    );
  }
}

Bar.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Bar.defaultProps = {
  links: [],
};

export default withStyles(styles)(Bar);
