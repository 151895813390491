import settings from '../../settings';

const { baseUrl } = settings;

const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const getPgnHeader = (headers, name) => {
  const value = headers?.find(e => e.name === name)?.value;
  if (!value) return null;
  if (name === "Date") {
    const parts = value.split('.').filter(e => !e.includes('?'));
    if (!parts) {
      return null;
    }
    return parts.join('.');
  } else {
    if (value.includes('?')) {
      return null;
    }
  }
  return value;
}

const prettyMoves = (moves, initTurn) => {
  let turn = initTurn;
  let out = '';
  for (const { move, move_number, comments, ravs} of moves) {
    if (move_number) {
      out += String.raw`${move_number}.${turn ? '' : '..'}\,`;
    }
    out += String.raw`\texttt{${move.replace('#', String.raw`\#`)}}`;
    if (comments.length > 0) {
      out += String.raw` \{${comments.map(({ text }) => text).join('; ')}\}`;
    }
    if (ravs) {
      for (const rav of ravs) {
        out += ` (${prettyMoves(rav.moves, turn)})`;
      }
    }
    out += ' ';
    turn = !turn;
  }
  return out.trim();
}

export const pgnToLaTeX = (pgn, options) => {
  const {
    leftHeader, rightHeader, leftFooter,
    includeSolutionSpace, includeGameInfo,
    includeSolutionPages,
    enumeratePagesFrom = 1,
    enumerateDiagramsFrom = 1,
  } = options;

  let gameInfo = String.raw`#4`;

  let solutionSpace = String.raw`\begin{center}(#1).\dotfill\ifthenelse{\equal{#3}{w}}{$\square$}{$\blacksquare$}\end{center}`;

  let noSolutionSpace = String.raw`\hphantom{xxx}(#1)\hfill\ifthenelse{\equal{#3}{w}}{$\square$}{$\blacksquare$}\hphantom{xxx}`;

  let showBoardWithPov = String.raw`\ifthenelse{\equal{#5}{white}}{$\showboard$}{$\showinverseboard$}`;

  let out = String.raw`\documentclass[a4paper, oneside]{article}
  \usepackage{graphicx}
  \usepackage[a4paper, left=1.5cm, right=1.5cm, top=1.75cm, bottom=1.75cm]{geometry}
  \usepackage{skak}
  \usepackage{tabularx}
  \usepackage{fancyhdr}
  \usepackage{hyperref}
  \usepackage{amssymb}
  \pagestyle{fancy}
  \fancyhf{}
  \lhead{${leftHeader}}
  \rhead{${rightHeader}}
  \lfoot{${leftFooter}}
  \rfoot{created in \href{${baseUrl}}{${baseUrl}}}
  \cfoot{\thepage}
  \begin{document}
  \setlength{\tabcolsep}{24pt}
  \setcounter{page}{${enumeratePagesFrom}}

  \newcommand{\diagram}[5]{
  \begin{center}
  ${includeGameInfo ? gameInfo : ''}
  \end{center}
  \fenboard{#2}
  \centerline{\scalebox{1.0}{${showBoardWithPov}}}
  ${includeSolutionSpace ? solutionSpace : noSolutionSpace}
  }

  \newcommand{\solution}[2]{
  \begin{flushleft}
  (#1) #2
  \end{flushleft}
  }

  \begin{center}`;

  const perPage = 6;
  const n = pgn.length;
  for (let i = 0; i < n; ++i) {
    const { headers } = pgn[i];
    const fen = getPgnHeader(headers, "FEN") || STARTING_FEN;
    const white = getPgnHeader(headers, "White");
    const black = getPgnHeader(headers, "Black");
    const event = getPgnHeader(headers, "Event");
    const date = getPgnHeader(headers, "Date");
    const pov = getPgnHeader(headers, "StartFlipped") === "1" ? "black" : "white";

    let eventInfo = [event, date].filter(e => !!e).join(', ');

    let info = String.raw`\phantom{X}`;
    const atLeastOnePlayerKnown = white !== 'N.N.' || black !== 'N.N.';
    if (white && black && atLeastOnePlayerKnown) {
      info = String.raw`\textbf{${white} - ${black}}`;
      if (eventInfo) {
        info += `, ${eventInfo}`;
      }
    } else if (eventInfo) {
      info = eventInfo;
    }
    info = info.replace('#', String.raw`\#`);

    if (i % perPage === 0) {
      out += String.raw`
      \begin{tabularx}{1.0\textwidth} {
      >{\raggedright\arraybackslash}X
      >{\raggedright\arraybackslash}X }`;
    }
    const turn = fen.split(' ')[1];
    out += String.raw`\diagram{${i+enumerateDiagramsFrom}}{${fen}}{${turn}}{${info}}{${pov}}`;
    if (i % 2 === 0) {
      out += String.raw` & `;
    } else {
      out += String.raw` \\ `;
    }
    if (((i+1) % perPage === 0) || ((i+1) === n)) {
      out += String.raw`\end{tabularx} `;
    }
    if (((i+1) % perPage === 0) && ((i+1) !== n)) {
      out += String.raw`\pagebreak `;
    }
  }

  out += String.raw`\end{center}`;
  if (includeSolutionPages) {
    let anySolution = false;
    for (let i = 0; i < n; ++i) {
      const { headers, moves } = pgn[i];
      const fen = getPgnHeader(headers, "FEN") || STARTING_FEN;
      if (!moves.length) continue;
      if (!anySolution) {
        out += String.raw`\newpage`;
        anySolution = true;
      }
      const turn = fen.split(' ')[1];
      out += String.raw`\solution{${i+enumerateDiagramsFrom}}{${prettyMoves(moves, turn === 'w')}}`;
    }
  }

  out += String.raw`\end{document}`;
  return out;
}

export const pgnToPdfUrl = (pgn, options) => {
  if (!pgn) return '';
  let latex = pgnToLaTeX(pgn.filter(e => !!e.headers || e.moves.length > 0), options);
  latex = encodeURIComponent(latex);
  return `https://texlive2020.latexonline.cc/compile?text=${latex}`;
}
