import settings from './settings';

const eventTypes = {
  premiumInputClick: {
    name: 'premium_input_click',
  },
  purchaseButtonClick: {
    name: 'purchase_button_click',
  },
};

const emitEvent = (eventType, options = {}) => {
  if (!settings.useAnalytics) {
    console.log(`---EMIT EVENT---`);
    console.log(`eventType=${JSON.stringify(eventType)}`);
    console.log(`options=${JSON.stringify(options)}`);
    console.log(`----------------`);
    return;
  }

  const { name, ...params } = eventType;

  // window.analytics is binded to Segment in public/index.html
  window.analytics.track(name, { ...params, ...options });
}

const identifyUser = user => {
  if (!settings.useAnalytics) {
    return;
  }

  const payload = { email: user.email }
  if (user.displayName) {
    payload.name = user.displayName;
  }
  window.analytics.identify(user.uid, payload);
}

const visitPage = () => {
  if (!settings.useAnalytics) {
    return;
  }

  window.analytics.page();
}

export {
  eventTypes,
  emitEvent,
  identifyUser,
  visitPage,
};
