import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import settings from '../settings';
import { logLinkClick } from '../utils';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  copyContainer: {
    marginTop: theme.spacing(4),
  },
}));

const footers = [
  {
    title: 'Company',
    items: [
      {
        name: 'Contact us',
        url: `mailto:${settings.contactEmail}`,
        external: true,
      },
      {
        name: 'Guide',
        url: 'https://chessvision.ai/docs',
        external: true,
      },
      {
        name: 'Blog',
        url: 'https://chessvision.ai/blog',
        external: true,
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        name: 'Contact us',
        url: `mailto:${settings.contactEmail}`,
        external: true,
      },
      {
        name: 'Discord Community',
        url: settings.discordInvite,
        external: true,
      }
    ],
  },
  {
    title: 'Products',
    items: settings.products.map(({ url, title }) => ({
      name: title,
      url,
      external: true,
    }))
  },
  {
    title: 'Resources',
    items: [
      {
        name: 'Terms of Service',
        url: 'https://chessvision.ai/terms/index.html',
        external: true,
      },
    ]
  },
];

const Copyright = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="center">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://chessvision.ai"
        target="_blank"
        onClick={() => { logLinkClick(`footer:copy`) }}
      >
        Chessvision.ai
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justifyContent="space-evenly">
        {footers.map(footer => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.items.map(item => (
                <li key={item.name}>
                  <Link
                    href={item.url}
                    target={item.external ? "_blank" : "_self"}
                    variant="subtitle1"
                    color="textSecondary"
                    onClick={() => { logLinkClick(`footer:${item.name}`) }}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Box mt={5} className={classes.copyContainer}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Footer;
